module.exports = [{
      plugin: require('/workspace/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"custom":{"families":["futura-pt"],"urls":["https://use.typekit.net/pen5ciw.css"]}},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-segment-js/gatsby-browser'),
      options: {"plugins":[],"prodKey":"78alqwoSHOxwTiI1Ry7rcV3eH46U7n3G","trackPage":true},
    }]
